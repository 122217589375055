<template>
  <v-container class="px-0" fluid>
    <v-checkbox
      v-model="value"
      label="Published"
    ></v-checkbox>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["value"],
  data: () => ({
  }),
  watch: {
    value: function (val) {
      this.$emit("input", val);
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>