<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Edit Series</v-card-title>
        <v-card-text>
          <template>
            <Country
              :value="form['countryCode']"
              @input="form['countryCode'] = $event"
            />
          </template>
          <template>
            <FormInput :value="form['name']" @input="form['name'] = $event" />
          </template>
          <template>
            <FormRadio :value="form['isPublished']" @input="form['isPublished'] = $event" />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Country from "@/components/Moderator/Series/Country";
import FormInput from "@/components/Moderator/Series/FormInput";
import FormRadio from '@/components/Moderator/Series/FormRadio.vue';
import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
    Country,
    FormInput,
    FormRadio
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      countryCode: 'MYS',
      name: null,
      isPublished: false,
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectCournty(code) {
      this.form.gameCode = code;
    },
    entertName(name) {
      this.form.name = name;
    },
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
          this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.form.name = this.self.name;
    this.form.countryCode = this.self.countryCode;
    this.form.isPublished = this.self.isPublished ? true : false;
  },
};
</script>

<style>
</style>